var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white"}},[(!_vm.loading)?_c('el-row',[_c('el-col',{staticClass:"tab-resources",attrs:{"span":24}},[_c('el-row',{staticClass:"action-toolbar",attrs:{"type":"flex","justify":"end"}},[_c('er-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"btnType":"circle_add","showLabel":true,"showIcon":true,"disabled":_vm.isReadOnly,"size":"mini"},on:{"click":function($event){_vm.handleClickAction($event, 'add', _vm.$lodash.cloneDeep(_vm.newResource))}}},[_vm._t("default",[_c('p',{staticStyle:{"text-transform":"uppercase"}},[_vm._v("\n              "+_vm._s(_vm.$t("Resource_add_reso"))+"\n            ")])])],2)],1),_vm._v(" "),_c('er-data-tables',{key:_vm.$i18n.locale,ref:"resourceTable",attrs:{"uniqueId":"resource__tab__table","actionColumn":false,"columns":_vm.tableColumns,"tableData":_vm.resources,"type":"white-header-table","elTableProps":_vm.tableProps,"page-size":_vm.$constants.table['page-size'],"pagination-props":_vm.$constants.table['pagination-props']},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.type === "FEED" && row.data.feed_type
              ? ((row.data.name) + " - " + (row.data.feed_type) + " ")
              : row.data.name)+"\n        ")]}},{key:"kg_dispensed_time",fn:function(ref){
              var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(row.data.kg_dispensed_time))+"\n        ")]}},{key:"units",fn:function(ref){
              var row = ref.row;
return [_c('span',{staticClass:"units-cell"},[_vm._v("\n            "+_vm._s(_vm.ftm__formatEmpty(row.data.units.toLowerCase()))+"\n          ")])]}},{key:"type",fn:function(ref){
              var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(_vm.resourceTypes[row.data.type], true))+"\n        ")]}},{key:"status",fn:function(ref){
              var row = ref.row;
return [_c('er-button',{attrs:{"showLabel":true,"showIcon":true,"type":"text","size":"mini","btnType":"edit","disabled":_vm.isReadOnly},on:{"click":function($event){_vm.handleClickAction($event, 'edit', _vm.$lodash.cloneDeep(row.data))}}}),_vm._v(" "),_c('el-divider',{attrs:{"direction":"vertical"}}),_vm._v(" "),_c('er-button',{attrs:{"showLabel":true,"showIcon":true,"type":"text","size":"mini","btnType":"delete","disabled":_vm.isReadOnly},on:{"click":function($event){return _vm.handleRemoveResource($event, row.data)}}})]}}],null,false,2273902101)}),_vm._v(" "),_c("resourceActionDialog",{tag:"component",attrs:{"showDialog":_vm.dialogVisible,"resource":_vm.selectedResource,"actionToPerform":_vm.actionToPerform},on:{"close_dialog":_vm.closeDialog}})],1)],1):(_vm.loading)?_c('Loader'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }