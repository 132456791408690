<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: VendorActionDialog
Description:This file contains the functionality of add the vendor details in the form
-->
<template>
  <er-dialog
    width="30%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @beforeClose="handleCloseDialog('close')"
    @open="handleOpenDialog"
    :title="
      actionToPerform === 'edit'
        ? $t('Resource_edit_vendor')
        : $t('Resource_new_vendor')
    "
    class="action-on-vendor"
    custom-class="vendor_action_dialog"
  >
    <el-form size="small" :model="formVendor" :inline="true">
      <el-form-item
        label-width="120px"
        :label="formStrings.name.label"
        class="input-name"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.name)"
      >
        <el-input
          v-model="formVendor.name"
          :placeholder="formStrings.name.placeholder"
        ></el-input>
      </el-form-item>

      <el-form-item
        label-width="120px"
        :label="formStrings.phone.label"
        class="input-feed-type"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.phone)"
      >
        <el-input
          v-model="formVendor.phone"
          :placeholder="formStrings.phone.placeholder"
        ></el-input>
      </el-form-item>
    </el-form>

    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        :loading="loading"
        @click="submitVendorDetails()"
        :disabled="isReadOnly"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog('close')"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
// import { vendorSchema } from "@/constants/validations/vendor";

// import * as yup from "yup";
// import { extend } from "vee-validate";

export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin],
  props: {
    vendor: {
      default: () => ({
        name: "",
        phone: ""
      })
    },
    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ""
    }
  },
  data: function() {
    return {
      loading: false,
      formVendor: this.vendor,
      newErrObject: {
        name: "",
        phone: ""
      },
      ehm__errMessagesObject: {
        name: "",
        phone: ""
      },

      ekmm__backndfieldToFieldNameMap: {
        name: "Resource_vendor_name",
        phone: "Comn_mobile_num"
      },
      ekmm__backndfieldToInvalidMap: {
        name: {
          FIELD_REQUIRED: "Comn_name",
          INVALID_VALUE: "Comn_name",
          NAME_ALREADY_TAKEN: "Resource_vendor_name_already_exists"
        },
        phone: {
          FIELD_REQUIRED: "Comn_name",
          INVALID_VALUE: "Comn_mobile_num",
          PHONE_NUMBER_ALREADY_TAKEN: "Usrs_phone_num_already_in_use",
          Invalid_phone_number: "Invalid_phone_number"
        }
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),

    vendors: function() {
      return this.$store.getters["resource/getArrVendors"];
    },

    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    },

    formStrings() {
      return {
        phone: {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Comn_mobile_num")
        },
        name: {
          label: this.$t("Resource_vendor"),
          placeholder: this.$t("Resource_vendor")
        }
      };
    }
  },

  methods: {
    handleCloseDialog(event) {
      this.$emit("close_dialog", event);
      this.ehm__errMessagesObject = this.$lodash.cloneDeep(this.newErrObject);
    },
    handleOpenDialog() {
      this.formVendor = this.vendor;
    },
    async submitVendorDetails() {
      this.loading = true;
      this.formVendor.name = this.formVendor.name.trim();
      this.formVendor.phone = this.formVendor.phone.trim();
      const names = this.vendors;
      const phonenumbers = this.vendors;

      const funcValue = (acc, x) => {
        if (this.actionToPerform === "edit" && x._id === this.formVendor._id) {
          return acc;
        }
        acc.push(["name"].map(key => x[key].toLowerCase().trim()).join("__"));
        return acc;
      };
      const existingResourceNames = new Set(names.reduce(funcValue, []));
      const isNameAlreadyTaken = existingResourceNames.has(
        ["name"]
          .map(key => this.formVendor[key].toLowerCase().trim())
          .join("__")
      ); // name Validation

      const phonefunc = (acc, x) => {
        if (this.actionToPerform === "edit" && x._id === this.formVendor._id) {
          return acc;
        }
        acc.push(["phone"].map(key => x[key].toLowerCase().trim()).join("__"));
        return acc;
      };
      const existingResourceNames1 = new Set(
        phonenumbers.reduce(phonefunc, [])
      );
      const isPhoneAlreadyTaken = existingResourceNames1.has(
        ["phone"]
          .map(key => this.formVendor[key].toLowerCase().trim())
          .join("__")
      ); // phone number validation

      this.ehm__errMessagesObject = this.$lodash.cloneDeep(this.newErrObject);
      if (isNameAlreadyTaken) {
        this.ehm__errMessagesObject.name = {
          error_code: "NAME_ALREADY_TAKEN",
          key: "name"
        };
        this.loading = false;
      } else if (isNaN(this.formVendor.phone)) {
        this.ehm__errMessagesObject.phone = {
          error_code: "Invalid_phone_number",
          key: "phone"
        };
        this.loading = false;
      } else if (isPhoneAlreadyTaken) {
        this.ehm__errMessagesObject.phone = {
          error_code: "PHONE_NUMBER_ALREADY_TAKEN",
          key: "phone"
        };
        this.loading = false;
      } else if (
        this.actionToPerform === "edit" &&
        this.formVendor.phone.length === 0 &&
        this.formVendor.name.length === 0
      ) {
        this.ehm__errMessagesObject.phone = {
          error_code: "FIELD_REQUIRED",
          key: "phone"
        };

        this.ehm__errMessagesObject.name = {
          error_code: "FIELD_REQUIRED",
          key: "name"
        };
        this.loading = false;
      } else if (
        this.actionToPerform === "edit" &&
        this.formVendor.phone.length === 0
      ) {
        this.ehm__errMessagesObject.phone = {
          error_code: "FIELD_REQUIRED",
          key: "phone"
        };
        this.loading = false;
      } else if (
        this.actionToPerform === "edit" &&
        this.formVendor.name.length === 0
      ) {
        this.ehm__errMessagesObject.name = {
          error_code: "FIELD_REQUIRED",
          key: "name"
        };
        this.loading = false;
      } else {
        try {
          this.formVendor.name = this.formVendor.name.trim();
          this.formVendor.phone = this.formVendor.phone.trim();

          const storeAction =
            this.actionToPerform === "edit"
              ? "resource/updateVendor"
              : "resource/addVendor";
          const response = await this.$store.dispatch(
            storeAction,
            this.formVendor
          );
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t(
              this.actionToPerform === "add"
                ? "Resource_vendor_details_saved_success"
                : "Resource_vendor_details_updated_success"
            ),
            duration: 5000,
            type: "success"
          });
          console.log(response);
          this.handleCloseDialog("vendor-action-" + this.actionToPerform);
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.aciton-on-vendor {
  .el-dialog__body {
    padding: 15px 15px;
  }

  .el-input-number {
    width: 100%;
  }
}
.vendor_action_dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 205px, 227px, 250px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 130px, 160px, 210px);
  }
}
</style>
