<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--                                                                                    File Name: resources.vue                                                               Description: This file contains the combination of  the resourcesTab ,vendorsTab,labsTab                                                                  -->
<template>
  <layout-pondlogs headerType="card" class="resources-view">
    <template slot="header">
      <er-card>
        <er-top-route-nav type="tabs" :items="filterMenuItemsByPermissions">
        </er-top-route-nav>
        <component
          :is="computedComponentName"
          :tabData="components[computedComponentName]"
        ></component>
      </er-card>
    </template>
  </layout-pondlogs>
</template>

<script>
import { mapGetters } from "vuex";
import resourcesTab from "@/components/manage/resourcesTab";
import vendorsTab from "@/components/manage/vendorsTab";
import labsTab from "@/components/manage/labsTab";
import redirectsMixin from "@/mixins/redirectsMixin";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
import {
  permissionsToStringMap as permissions,
  isValidTabName,
  getFirstTabBasedOnPermissions
} from "@/middleware/pageAccessManager";
const tabToPermission = {
  resourcesTab: {
    name: "resourcesTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_MEDICINE, permissions.MANAGE_SCHEDULES, permissions.MANAGE_STOCK]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_STOCK, permissions.MANAGE_MEDICINE, permissions.MANAGE_SCHEDULES],
      UPDATE: [permissions.MANAGE_STOCK, permissions.MANAGE_MEDICINE],
      DELETE: [permissions.MANAGE_STOCK, permissions.MANAGE_MEDICINE]
    }
  },
  vendorsTab: {
    name: "vendorsTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_STOCK]
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_STOCK],
      UPDATE: [permissions.MANAGE_STOCK],
      DELETE: [permissions.MANAGE_STOCK]
    }
  },
  labsTab: {
    name: "labsTab",
    keyToValidateAccess: "PERMISSIONS",
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_LAB_TESTS], // permissions.MANAGE_STOCK,
      UPDATE: [permissions.MANAGE_LAB_TESTS], // permissions.MANAGE_STOCK, removed based on excel
      DELETE: [permissions.MANAGE_LAB_TESTS]
    }
  }
};

export default {
  name: "resources",
  mixins: [redirectsMixin],
  components: {
    resourcesTab,
    vendorsTab,
    labsTab
  },
  data: function () {
    return {
      loaded: true
    };
  },
  beforeRouteEnter(to, from, next) {
    handleStoreRegistration(to, from);
    next((component) => {
      const viewPermissions =
        component.$store.getters["user/getViewPermissions"];
      const userType = component.$store.getters["user/getCurrUserType"];
      const tabName = component.$route.query.tab;
      if (
        !isValidTabName(tabName, tabToPermission, viewPermissions, userType)
      ) {
        component.rdm__navigateToPathWithLocationId({
          path: "/user/resources",
          query: {
            tab: getFirstTabBasedOnPermissions(
              tabToPermission,
              viewPermissions,
              userType
            )
          }
        });
      }
    });
  },
  computed: {
    ...mapGetters("user", {
      getViewPermissions: "getViewPermissions"
    }),
    computedComponentName: function () {
      return this.$route.query.tab;
    },
    components() {
      return {
        resourcesTab: {
          label: this.$t("Comn_resources"),
          ...tabToPermission.resourcesTab
        },
        vendorsTab: {
          label: this.$t("Resource_vendors"),
          ...tabToPermission.vendorsTab
        },
        labsTab: {
          label: this.$t("Comn_labs"),
          ...tabToPermission.labsTab
        }
      };
    },
    filterMenuItemsByPermissions: function () {
      return this.$gblUAMFilterItemsUserCanView(this.components);
    }
  }
};
</script>

<style lang="scss">
.resources-view {
  $default-font-size: 13px;
  $font-color: #404243;
  .el-menu--horizontal.type-tab {
    @include responsiveProperty(margin-bottom, 10px, 10px, 5px);
    width: 100%;
    > .el-menu-item {
      height: 35px;
      line-height: 35px;
    }
  }
  .action-toolbar {
    margin-bottom: 10px;
  }
  .el-card__body {
    padding: 0px;
  }
}
</style>
